/* eslint-disable */
export class Conf {

  static idempiereUrl = "https://idempiere.seritec.eu/"

  static windowTitle = 'CERTIFICO SERITEC'

  static buildNo = "20241016144951"

}
